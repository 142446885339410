@import url("./fonts/style.css");

:root {
  --font-family-main: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}
:root[dir="rtl"] {
  --font-family-main: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

* {
  font-family: var(--font-family-main);
  font-weight: normal;
}

html,
body,
#root {
  height: 100%;
  background-color: #ecf0f5;
}

body {
  margin: 0;
  font-family: var(--font-family-main);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App-map {
  height: 100vh;
  width: 100%;
}

@media print {
  .printArea {
    display: block;
  }
}
.printArea {
  display: none;
}

html[dir="rtl"] .mobile-phone-rtl-fix {
  unicode-bidi: embed;
  direction: ltr;
  text-align: right;
}
